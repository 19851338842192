import { DirectionOrderTypes } from "app/modulos/cliente-beta/constants/listagem";

export type ProdutividadeSituacaoPesquisas = "em_auditoria" | "nao_auditada" | "pesquisas" | "auditadas";

export enum ProdutividadeSituacaoPesquisasFriendlyNames {
    em_auditoria = "Em Auditoria",
    nao_auditada = "Não Auditada",
    pesquisas = "Pesquisas",
    auditadas = "Auditadas",
}

export interface IInfoCardPayload {
  pesquisas: number,
  auditadas: number,
  em_auditoria: number,
  nao_auditada: number
}

export enum OperatorSortFilter {
  NOME = 'NOME',
  COLETAS_CUMPRIDAS = 'COLETAS_CUMPRIDAS',
  DIAS_TRABALHADOS = 'DIAS_TRABALHADOS',
  COLETAS_APROVADAS = 'COLETAS_APROVADAS',
  AVALIACAO_GERAL = 'AVALIACAO_GERAL'
}

interface IOperatorCollections {
  aprovadas: number;
  cumpridas: number;
  esperadas: number;
  reprovadas: number;
}

interface IOperatorPhoto {
  arquivo: string;
}
export interface IOperator {
  avaliacao_geral: number;
  coletas: IOperatorCollections;
  dias_trabalhados: number;
  foto: IOperatorPhoto;
  id: number;
  nome: string;
}

interface ISorting {
  direction: DirectionOrderTypes;
  sort: OperatorSortFilter;
};

export interface IFilter {
  keywords: string[];
  sorting: ISorting;
}

export interface IPagination {
  current: number;
  previus: number;
}

export interface IFilterTotalOperators {
  palavrasChave: string[];
}
