<header>

  <section class="content-header" *ngIf="hasCollections">
    <h1>Estes entrevistadores fizeram a diferença</h1>
    <p>
      Confira as maiores notas de desempenho
      <span (mouseenter)="showTooltip = true" (mouseleave)="showTooltip = false">
        <span class="tooltip-container" *ngIf="showTooltip">
          As notas de desempenho dos entrevistadores são baseadas na aprovação em auditoria, tempo médio de coleta e médias diárias, com pesos variáveis para cada fator.
        </span>
        <i class="fa-regular fa-circle-info"></i>
      </span>
    </p>
    <a (click)="navigate()">Ver todos os entrevistadores<i class="fa-solid fa-angle-right"></i></a>
  </section>
  <div class="background-svg">
    <svg width="807" height="350" viewBox="0 0 807 350" fill="none" xmlns="http://www.w3.org/2000/svg">
      <mask id="mask0_7047_8739" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="807" height="350">
      <rect width="807" height="350" rx="4" transform="matrix(-1 8.74228e-08 8.74228e-08 1 807 0)" fill="white"/>
      </mask>
      <g mask="url(#mask0_7047_8739)">
      <g clip-path="url(#clip0_7047_8739)">
      <path class="path-light" d="M811.693 -5L811.693 82.0831C782.988 84.3612 754.012 87.4111 724.998 91.2114C659.754 99.7701 594.004 112.193 529.568 128.14C518.377 130.907 507.219 133.787 496.097 136.773C493.8 124.217 491.342 112.028 488.736 100.212C478.95 55.8906 468.025 21.5226 457.366 -4.99465L811.693 -4.99468L811.693 -5Z" fill="#F4EDF7" fill-opacity="0.4"/>
      <path class="path-dark" d="M724.998 91.2123C754.012 87.4119 782.988 84.3621 811.693 82.084L811.693 403.112L520.893 403.112C520.808 399.764 520.723 396.41 520.622 393.052C517.814 296.234 509.575 210.242 496.097 136.774C507.219 133.793 518.377 130.908 529.568 128.141C593.999 112.194 659.749 99.771 724.998 91.2123Z" fill="#610082" fill-opacity="0.1"/>
      <path class="path-light" d="M-2.99949 403.111L-2.99949 357.465C18.9136 343.376 41.082 329.792 63.4684 316.763C198.814 237.977 344.2 177.502 496.103 136.773C509.575 210.241 517.814 296.233 520.627 393.052C520.723 396.415 520.813 399.763 520.898 403.111L-2.99412 403.111L-2.99949 403.111Z" fill="#F4EDF7" fill-opacity="0.4"/>
      </g>
      </g>
      <defs>
      <clipPath id="clip0_7047_8739">
      <rect width="814.693" height="408.111" fill="white" transform="matrix(-1 8.74228e-08 8.74228e-08 1 811.693 -5)"/>
      </clipPath>
      </defs>
      </svg>
  </div>

  <section class="content-operator" *ngIf="hasCollections">
    <app-operator-card
      [operadorData]="podiumData?.podio[1] ? podiumData?.podio[1] : null"
      [position]="podiumData?.podio[1]?.id ? podiumData?.podio[1].id : 2"
    ></app-operator-card>

    <app-operator-card
      [operadorData]="podiumData?.podio[0] ? podiumData?.podio[0] : null"
      [position]="podiumData?.podio[0]?.id ? podiumData?.podio[0].id : 1"
    ></app-operator-card>

    <app-operator-card
      [operadorData]="podiumData?.podio[2] ? podiumData?.podio[2] : null"
      [position]="podiumData?.podio[2]?.id ? podiumData?.podio[2].id : 3"
    ></app-operator-card>
  </section>

  <section class="content-no-collections" *ngIf="!hasCollections">
    <h3>A pesquisa ainda não possui coletas</h3>
    <h4>As maiores notas de desempenho aparecerão aqui</h4>
    <a (click)="navigate()">Ver todos os entrevistadores<i class="fa-solid fa-angle-right"></i></a>
  </section>

</header>
