import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { AuditoriaObservationsModalService } from "app/componentes/audit-observations/modal-observations.service";
import { IItemBreadcrumb } from "app/componentes/breadcrumb/breadcrumb.interface";
import {
  FilterIcon,
  FilterTypes,
} from "app/componentes/filter-select/constants";
import {
  FilterConfigs,
  SearchInputConfig,
} from "app/util/componente/genericTable/interfaces/filters";
import { TableHead } from "app/util/componente/genericTable/interfaces/tHead";
import { TableRow } from "app/util/componente/genericTable/interfaces/tRow";
import {
  TableEvents,
  TableGlobalConfig,
} from "app/util/componente/genericTable/interfaces/table";
import {
  iconCellFactory,
  statusCellFactory,
  textCellFactory,
} from "app/util/componente/genericTable/utils/cellFactory";
import { tHeadFactory } from "app/util/componente/genericTable/utils/tHeadFactory";
import { ModalData } from "app/util/componente/prompt-modal/prompt-modal.component";
import { IOperadorData } from "../interfaces";
import { ProdutividadeBetaService } from "../services/produtividade-beta.service";
import { ErrorHandlerService } from "app/servico/requestService/error-handler.service";
import { errorMessages } from "../constants/listagem-operadores";

@Component({
  selector: "app-detalhes-operador",
  templateUrl: "./detalhes-operador.component.html",
  styleUrls: ["./detalhes-operador.component.scss"],
})
export class DetalhesOperadorComponent implements OnInit {
  surveyId: number;
  collectionId: number;
  operatorId: number;
  surveyTitle: string = "Avaliação da Pandemia pelo Governo Federal do estado do Piauí";
  dataBreadcrumb: IItemBreadcrumb[] = []
  observationsModal: ModalData = null; // Estado da modal de observações.
  operador: IOperadorData = null;
  isLoading: boolean = false;

  tBodyOperadorColetas: TableRow[] = [
    {
      id: 1,
      cells: [
        textCellFactory("1").build(),
        textCellFactory("Conjunto Habitacional Fazenda Maria").build(),
        textCellFactory("14/07/23").build(),
        textCellFactory("10:03:30").build(),
        textCellFactory("10:03:30").build(),
        textCellFactory("1min 30s").build(),
        statusCellFactory("var(--status-error)", "Reprovada", "").build(),
        iconCellFactory(["fa-regular fa-message-lines"], "button")
          .metadata(1)
          .build(),
      ],
    },
    {
      id: 2,
      cells: [
        textCellFactory("2").build(),
        textCellFactory("Conjunto Habitacional Fazenda Maria").build(),
        textCellFactory("14/07/23").build(),
        textCellFactory("10:03:30").class("vaidatudocerto").build(),
        textCellFactory("10:03:30").build(),
        textCellFactory("1min 30s").build(),
        statusCellFactory("var(--status-done)", "Aprovada", "").build(),
        iconCellFactory(["fa-regular fa-message-lines"], "button")
          .metadata(0)
          .build(),
      ],
    },
    {
      id: 3,
      cells: [
        textCellFactory("3").build(),
        textCellFactory("Conjunto Habitacional Fazenda Maria").build(),
        textCellFactory("14/07/23").build(),
        textCellFactory("10:03:30").build(),
        textCellFactory("10:03:30").build(),
        textCellFactory("1min 30s").build(),
        statusCellFactory("var(--gray200)", "Não auditada", "").build(),
        iconCellFactory(["fa-regular fa-message-lines"], "button")
          .metadata(1)
          .build(),
      ],
    },
    {
      id: 5,
      cells: [
        textCellFactory("5").build(),
        textCellFactory("Conjunto Habitacional Fazenda Maria").build(),
        textCellFactory("14/07/23").build(),
        textCellFactory("10:03:30").build(),
        textCellFactory("10:03:30").build(),
        textCellFactory("1min 30s").build(),
        statusCellFactory("var(--gray200)", "Não auditada", "").build(),
        iconCellFactory(["fa-regular fa-message-lines"], "button")
          .metadata(1)
          .build(),
      ],
    },
  ];

  tHeadOperadorColetas: TableHead[] = [
    tHeadFactory("ID").build(),
    tHeadFactory("Localidade")
      .build(),
    tHeadFactory("Data").build(),
    tHeadFactory("Início").build(),
    tHeadFactory("Fim").build(),
    tHeadFactory("Duração").build(),
    tHeadFactory("Situação").build(),
    tHeadFactory("Observações")
      .cellAlign('center')
      .cellStyles((cs) => {
        if (cs.metadata === 0) {
          return {
            color: "var(--gray200)",
            "pointer-events": "none",
          };
        } else {
          return null;
        }
      })
      .build(),
  ];

  tableConfig: TableGlobalConfig = {
    headerHeight: "60px",
    colWidths: ["0.06", "0.25", "0.09", "0.09", "0.09", "0.1", "0.19", "0.13"],
    rowStyles: {
      fontSize: "14px",
    },
  };

  tableFilter: FilterConfigs[] = [
    {
      type: FilterTypes.CHECK,
      placeholder: "Situação",
      icon: FilterIcon.FUNNEL,
      options: [
        {
          id: 1,
          label: "Aprovada",
        },
        {
          id: 2,
          label: "Reprovada",
        },
        {
          id: 3,
          label: "Não auditada",
        },
      ],
    },
    {
      type: FilterTypes.RANGE_DATE,
      placeholder: "Data",
      icon: FilterIcon.CALENDAR,
      selectConfig: {
        intervalDate: {
          endDate: new Date("2023-05-01"),
          startDate: new Date("2015-05-01"),
        },
      },
    },
    {
      type: FilterTypes.RADIO,
      placeholder: "Ordenar",
      icon: FilterIcon.SORTING,
      options: [
        {
          id: 1,
          label: "Coletas mais recentes",
        },
        {
          id: 2,
          label: "Coletas mais antigas",
        },
        {
          id: 3,
          label: "Maior duração",
        },
        {
          id: 4,
          label: "Menor duração",
        },
      ],
    },
  ];

  searchConfig: SearchInputConfig = {
    placeholder: "Busque por Id ou localidade",
  };

  constructor(
    private route: ActivatedRoute,
    private produtividadeService: ProdutividadeBetaService,
    private observationsModalService: AuditoriaObservationsModalService,
    private errorService: ErrorHandlerService
  ) {}

  ngOnInit(): void {
    this.initObservationsModalData();
    this.surveyId = +this.route.snapshot.paramMap.get("surveyId");
    this.operatorId = +this.route.snapshot.paramMap.get("entrevistadorId");
    this.getOperatorData();

    this.dataBreadcrumb = [
      {
        itemName: "início",
        itemLink: "/",
        active: false,
      },
      {
        itemName: "Produtividade",
        itemLink: "/produtividade-beta",
        active: false,
      },
      {
        itemName: "Avaliação da Pandemia pelo Governo Federal do estado do Piauí",
        itemLink: `/produtividade-beta/visao-geral/${this.surveyId}/operadores/operador/${this.operatorId}`,
        active: true,
      },
    ];
  }

  /**
   * Recupera os dados do operador
   */
  getOperatorData() {
    this.isLoading = true;
    this.produtividadeService.getOperatorData(this.surveyId, this.operatorId).subscribe({
      next: (operator: IOperadorData) => {
        if (operator) {
          this.buildOperatorHeader(operator);
        }
        this.isLoading = false;
      },
      error: (err) => {
        this.errorService.handleError(err, errorMessages.operatorHeadData.title);
        this.isLoading = false;
      }
    })
  }

  // constroi o objeto de operador para renderiar o header
  buildOperatorHeader(operator: IOperadorData) {
    this.operador = {
      id: operator.id,
      foto: operator.foto,
      nome: operator.nome,
      matricula: operator.matricula,
      coletas: {
        cumpridas: operator.coletas.cumpridas,
        auditadas: operator.coletas.auditadas,
        aprovadas: operator.coletas.aprovadas,
        reprovadas: operator.coletas.reprovadas,
      },
      dias_trabalhados: operator.dias_trabalhados,
      avaliacao_geral: operator.avaliacao_geral,
      tempo_medio: operator.tempo_medio
    };
  }

  initObservationsModalData() {
    this.observationsModal = this.observationsModalService.modalData;
  }

  // eventos da tabela
  handleTableEvents(event: TableEvents): void {
    if (event.eventType === "CELL_ICON_CLICK") {
      this.observationsModal.show = true
      this.collectionId = +event.rowId;
    }
  }
}
